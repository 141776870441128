import dynamic from "next/dynamic"
import { usePathname, useSearchParams } from "next/navigation"
import { useIsFirstRender } from "usehooks-ts"

import useLocale from "~/hooks/useLocale"
import { getMarketRedirect, marketCookie } from "~/components/ui/MarketPopin/utils"
import { usePopin } from "~/managers/PopinManager"

export function useMarketRedirect({ country, lang }: { country: string; lang: string }) {
  const locale = useLocale()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  return getMarketRedirect({ lang, country, locale, pathname, searchParams })
}

const MarketPopin = dynamic(() => import("~/components/ui/MarketPopin"), { ssr: false })

export function useMarketPopinInit() {
  const { add } = usePopin()
  const isFirstRender = useIsFirstRender()

  if (isFirstRender && !marketCookie.hasInteracted.get()) {
    add(<MarketPopin type="confirm" />)
  }
}
