import type { ComponentProps, ReactNode } from "react"
import clsx from "clsx"

import { usePanelIndex } from "~/components/abstracts/Navigation/hooks"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

type AbstractDesktopTriggerProps = {
  index: number
  children: ReactNode | ((isActive: boolean) => ReactNode)
  panelDisplayed: boolean
  diagonalSelectionHeight?: number
} & Omit<ComponentProps<"div">, "children">

export function AbstractDesktopTrigger({
  index,
  panelDisplayed,
  diagonalSelectionHeight = 24,
  children,
  className,
  onMouseEnter,
  onMouseLeave,
  ...props
}: AbstractDesktopTriggerProps) {
  const [currentPanelIndex, setPanelIndex] = usePanelIndex()

  const isActive = currentPanelIndex === index

  return (
    <div
      data-comp={"Abstracts/Navigation/DesktopTrigger"}
      className={clsx(className, sprinkles({ position: "relative" }))}
      key={index}
      {...props}
      onMouseEnter={(event) => {
        onMouseEnter?.(event)
        setPanelIndex(index)
      }}
      onMouseLeave={(event) => {
        onMouseLeave?.(event)
        setPanelIndex(null)
      }}
    >
      {typeof children === "function" ? children(isActive) : children}
      {panelDisplayed && isActive && (
        <div className={css.DiagonalSelection} style={{ height: diagonalSelectionHeight }} />
      )}
    </div>
  )
}
