"use client"

import clsx from "clsx"

import { AccordionPanel } from "@unlikelystudio/react-abstract-components"

import type { InferReturn, PropsWithClassName } from "~/@types/generics"
import InlineCta from "~/components/ui/InlineCta"
import Icon from "~/components/abstracts/Icon"
import type serializeFooterColumn from "~/components/globals/Footer/components/FooterColumn/_data/serializer"

import { hideFrom, showFrom } from "~/styles/utils/show-hide"

import * as css from "./styles.css"

type FooterColumnProps = PropsWithClassName<InferReturn<typeof serializeFooterColumn> & { index: number }>

function FooterColumn({ className, title, index, links }: FooterColumnProps) {
  return (
    <div className={className}>
      <AccordionPanel
        className={clsx(css.FooterColumn, hideFrom("desktop", "block"))}
        ariaLabels={{ close: title, open: title }}
        headClassName={clsx(css.head)}
        index={index}
        head={(isActive: boolean) => (
          <>
            <p className={css.headTitle}>{title}</p>{" "}
            <Icon className={css.headIcon} name={isActive ? "Minus" : "Plus"} width={12} />
          </>
        )}
        body={() => (
          <div className={css.bodyLinks}>
            {links.map(({ link }, linkIndex) => (
              <InlineCta
                className={css.bodyLinkItem}
                key={`footer_column_${index}_link_${linkIndex}_mobile`}
                {...link}
                color="grey-tundora"
                withLine={false}
                size="small"
              />
            ))}
          </div>
        )}
      />
      <div className={clsx(css.FooterColumn, showFrom("desktop", "block"))}>
        <p className={css.headTitle}>{title}</p>
        <div className={css.bodyLinks}>
          {links.map(({ link }, linkIndex) => (
            <InlineCta
              className={css.bodyLinkItem}
              key={`footer_column_${index}_link_${linkIndex}`}
              {...link}
              size="small"
              color="grey-tundora"
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export { FooterColumn, type FooterColumnProps }
