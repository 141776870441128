"use client"

import { useEffect } from "react"
import { useInterval, useTimeout } from "usehooks-ts"

import { useUserhasInteracted } from "~/managers/UserInteraction/hooks"

export function ClientUserInteractionManager() {
  const { userHasInteracted, update } = useUserhasInteracted()
  const setInteracted = () => update(true)
  useInterval(
    () => update(navigator?.userActivation ? navigator?.userActivation?.hasBeenActive ?? false : true),
    userHasInteracted ? null : 200
  )
  useTimeout(() => update(true), 3000)
  useEffect(() => {
    window.addEventListener("scroll", setInteracted)
    window.addEventListener("mousemove", setInteracted)

    return () => {
      window.removeEventListener("scroll", setInteracted)
      window.removeEventListener("mousemove", setInteracted)
    }
  }, [])

  useEffect(() => {
    if (userHasInteracted) {
      window.removeEventListener("scroll", setInteracted)
      window.removeEventListener("mousemove", setInteracted)
    }
  }, [userHasInteracted])
  return <></>
}
