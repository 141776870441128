"use client"

import { useEffect, useState } from "react"
import { useInterval } from "usehooks-ts"

import { useCookiesConsentConfig } from "~/managers/CookiesConsentManager/hooks"

declare global {
  interface Window {
    _axcb?: any[]
  }
}

const TRIGGER_DELAY = 100

function stopPropagation(event: Event) {
  event.stopPropagation()
}

const axeptioClassName = ".axeptio_mount"

export function CookiesConsentHandler() {
  const [isLoaded, setIsLoaded] = useState(false)

  const [, setCookiesConsentConfig] = useCookiesConsentConfig()
  useInterval(
    () => {
      if (window._axcb && window._axcb.hasOwnProperty("push")) {
        window._axcb.push(function (axeptio: any) {
          axeptio.on("ready", () => {
            document?.querySelector(axeptioClassName)?.addEventListener("mousedown", stopPropagation)
          })
          axeptio.on("cookies:complete", function (props: any) {
            setCookiesConsentConfig(props)
          })
        })
        setIsLoaded(true)
      }
    },
    isLoaded ? null : TRIGGER_DELAY
  )

  useEffect(() => {
    return () => {
      axeptioClassName && document?.querySelector(axeptioClassName)?.removeEventListener("mousedown", stopPropagation)
    }
  }, [])

  return null
}
