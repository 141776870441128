"use client"

import { useParams } from "next/navigation"
import clsx from "clsx"

import { isPablo } from "~/lib/brand"
import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"
import { Link } from "~/components/ui/Link"
import { getPath } from "~/components/ui/Link/utils/href-resolver"
import Icon from "~/components/abstracts/Icon"
import { Logo } from "~/components/globals/Navigation/components/ClientNavigationLogo/Logo"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

type NavigationLogoProps = {
  logoAlt: string
  iconClassName?: string
  hasHeading1?: boolean
  footer?: boolean
}

export function NavigationLogo({ iconClassName, hasHeading1 = false, footer = false }: NavigationLogoProps) {
  const params = useParams()
  const t = useTranslate()
  const logo =
    footer && isPablo ? (
      <Icon className={iconClassName} direction="right" name="LogoFooterPablo" />
    ) : (
      <Logo className={iconClassName} />
    )

  return (
    <Link
      className={clsx(css.navigationLogoLink, sprinkles({ display: "inline-flex", textAlign: "center" }))}
      href={getPath(getProcessedLocale(params.locale), "/", {})}
      aria-label={t("cta_home_page")}
    >
      {hasHeading1 ? <h1 className={sprinkles({ width: "100%" })}>{logo}</h1> : logo}
    </Link>
  )
}
