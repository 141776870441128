import type { Nullish } from "~/@types/generics"

export function getCountryDisplayName(country: Nullish<string>, lang: Nullish<string>) {
  if (!lang || !country) return undefined

  return new Intl.DisplayNames(lang, { type: "region" }).of(country)
}

export function getLanguageDisplayName(lang: Nullish<string>) {
  if (!lang) return undefined

  return new Intl.DisplayNames(lang, { type: "language" }).of(lang)?.toLowerCase()
}

export function getCurrencyDisplayName(currency: Nullish<string>, lang: Nullish<string>) {
  if (!lang || !currency) return undefined

  return new Intl.NumberFormat(lang, { style: "currency", currency })
    .formatToParts(1)
    .find((x) => x.type === "currency")?.value
}
