import type { MouseEvent } from "react"
import { usePathname } from "next/navigation"
import clsx from "clsx"

import type { InferReturn, PropsWithClassName } from "~/@types/generics"
import InlineCta from "~/components/ui/InlineCta"
import { usePanelIndex } from "~/components/abstracts/Navigation/hooks"
import type { serializeLinksListWithTitle } from "~/components/globals/Navigation/components/LinksListWithTitle/_data/serializer"

import * as css from "./styles.css"

type LinksListWithTitleProps = PropsWithClassName<
  InferReturn<typeof serializeLinksListWithTitle> & {
    activeLinkFn?: (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => void
  }
>

function LinksListWithTitle({ className, title, links, activeLinkFn }: LinksListWithTitleProps) {
  const [_, setPanelIndex] = usePanelIndex()
  const pathName = usePathname()
  function onInlineCtaClick(e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>, isActive: boolean) {
    setPanelIndex(null)
    if (isActive) {
      activeLinkFn?.(e)
    }
  }

  return (
    <div className={clsx(className, css.LinksListWithTitle)}>
      {title && <p className={clsx(css.title, css.item)}>{title}</p>}
      {links.map(({ link }, index) => {
        const { href, ...rest } = link
        const isActive = href === pathName

        return (
          <div key={`list_item_index_${index}`} className={css.item}>
            <InlineCta withLine size="small" {...rest} href={href} onClick={(e) => onInlineCtaClick(e, isActive)} />
          </div>
        )
      })}
    </div>
  )
}

export { LinksListWithTitle }
