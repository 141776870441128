"use client"

import clsx from "clsx"

import SquareCta from "~/components/ui/SquareCta"
import { AbstractStoryblokPreviewToaster } from "~/components/abstracts/StoryblokPreviewToaster"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

export interface StoryblokPreviewToasterProps {
  className?: string
  enabled: boolean
}

function StoryblokPreviewToaster({ className, enabled }: StoryblokPreviewToasterProps) {
  return (
    <AbstractStoryblokPreviewToaster
      className={clsx(css.StoryblokPreviewToaster, className)}
      titleClassName={clsx(text({ design: "neue-12", fontWeight: "medium" }), sprinkles({ marginBottom: 5 }))}
      title="Storyblok Preview Mode"
      enabled={enabled}
    >
      {({ onClose, onExit }) => (
        <>
          <SquareCta theme="backgroundWhite" onClick={onClose}>
            Close
          </SquareCta>
          <SquareCta className={sprinkles({ marginLeft: 5 })} theme="backgroundBlack" onClick={onExit}>
            Exit preview
          </SquareCta>
        </>
      )}
    </AbstractStoryblokPreviewToaster>
  )
}

export default StoryblokPreviewToaster
