"use client"

import { forwardRef, type ComponentProps } from "react"
import clsx from "clsx"
import { m } from "framer-motion"

import createStackComponentManager, { type ElementProps } from "~/components/abstracts/StackComponentManager"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

const [AbstractCardPopinManager, AbstractCardPopin, useCardPopin] = createStackComponentManager("multiple")

type PanelProps = ElementProps & {
  bgColor?: Sprinkles["backgroundColor"]
}

export const CardPopin = m(
  forwardRef<HTMLDivElement, PanelProps>(({ bgColor = "white", className, ...props }, ref?) => (
    <AbstractCardPopin ref={ref} className={clsx(sprinkles({ bgColor, pointerEvents: "all" }), className)} {...props} />
  ))
)

export const CardPopinManager = m(
  forwardRef<HTMLDivElement, ComponentProps<typeof AbstractCardPopinManager>>((props, ref?) => (
    <AbstractCardPopinManager ref={ref} className={clsx(css.CardPopinManager)} {...props} />
  ))
)

export { useCardPopin }
