import { useTransition, type ComponentProps, type ElementType, type ReactNode } from "react"

import { useNavigationEvents, useNavigationMobileVisibility } from "~/components/abstracts/Navigation/hooks"

type AbstractMobileToggleButtonProps = {
  children: {
    open: ReactNode
    close: ReactNode
  }
  openAriaLabel: string
  closeAriaLabel: string
  onOpen: () => void
  onClose: () => void
  as?: ElementType
} & Omit<ComponentProps<"button">, "children">

export function AbstractMobileToggleButton({
  onOpen,
  onClose,
  children,
  className,
  openAriaLabel,
  closeAriaLabel,
  as: Tag = "button",
  ...props
}: AbstractMobileToggleButtonProps) {
  const [pending, startTransition] = useTransition()
  const [visible, setVisible] = useNavigationMobileVisibility()

  function handleClick() {
    if (visible) {
      onClose()
    } else {
      onOpen()
    }

    startTransition(() => setVisible((prev) => !prev))
  }

  useNavigationEvents(() => setVisible(false))

  const isVisible = pending ? !visible : visible

  return (
    <Tag
      data-comp="Abstracts/Navigation/MobileToggleButton"
      onClick={handleClick}
      aria-label={visible ? openAriaLabel : closeAriaLabel}
      className={className}
      {...props}
    >
      {isVisible ? children.open : children.close}
    </Tag>
  )
}
