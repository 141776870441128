import { LOCALES } from "~/lib/i18n/constants"
import { getSplittedPathname } from "~/lib/i18n/utils/get-splitted-pathname"

export function getPathnameWithoutLocale(pathname: string) {
  const splitedPathname = getSplittedPathname(pathname)

  if (splitedPathname[0] && !LOCALES.includes(splitedPathname[0])) {
    return pathname
  }

  const pathnameWithoutLocale = splitedPathname.slice(1, splitedPathname.length).join("/")

  return pathnameWithoutLocale
}
