import { createContext, useMemo, type PropsWithChildren } from "react"

import useCurrency from "~/hooks/useCurrency"

import { COOKIE_KEYS } from "./constants"

const defaultValues = {
  expireDays: 7,
  maxProducts: 6,
  cookieAge: 365,
  cookieKey: COOKIE_KEYS.recentlyViewed,
}

export const ProductsViewedContext = createContext(defaultValues)

type ProviderProductsViewedProps = PropsWithChildren<{ cookieProps?: CookieProps }>

export type CookieProps = {
  expireDays?: number
  maxProducts?: number
  cookieAge?: number
  cookieKey?: string
}

export default function ProductsViewedProvider({ cookieProps = {}, children }: ProviderProductsViewedProps) {
  const currency = useCurrency()
  const { expireDays, maxProducts, cookieAge, cookieKey } = cookieProps
  const values = useMemo(
    () => ({
      ...defaultValues,
      cookieKey: `${defaultValues.cookieKey}-${currency}`,
      ...cookieProps,
    }),
    [expireDays, maxProducts, cookieAge, cookieKey]
  )

  return <ProductsViewedContext.Provider value={values}>{children}</ProductsViewedContext.Provider>
}
