"use client"

import dynamic from "next/dynamic"
import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { getLanguageDisplayName } from "~/lib/i18n/utils/get-display-name"
import { getCurrency, getIntl, getLang } from "~/lib/i18n/utils/get-i18n"
import useLocale from "~/hooks/useLocale"
import Icon from "~/components/abstracts/Icon"
import { usePopin } from "~/managers/PopinManager"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

const MarketPopin = dynamic(() => import("~/components/ui/MarketPopin"), { ssr: false })

type LangSwitcherProps = PropsWithClassName

export function getCountryEmojiFromCountryCode(countryCode: string) {
  // for WW we display a 🌎 emoji
  // 127758 is decimal code for this one
  if (countryCode === "WW") return String.fromCodePoint(127758)

  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0))
  return String.fromCodePoint(...codePoints)
}

function LangSwitcher({ className }: LangSwitcherProps) {
  const locale = useLocale()
  const lang = getLang(locale)
  const currency = getCurrency(locale)
  const displayName = getLanguageDisplayName(lang)

  const intl = getIntl(locale)
  const regionCode = intl.split("-")[1] ?? ""
  const label = `${getCountryEmojiFromCountryCode(regionCode)} ${currency}`

  const { add } = usePopin()

  const onClick = () => {
    add(<MarketPopin />)
  }

  const processedCountryCurrency = <span className={sprinkles({ textTransform: "uppercase" })}>{label}</span>
  const processedLang = <span className={sprinkles({ textTransform: "capitalize" })}>{displayName}</span>

  return (
    <button className={clsx(css.LangSwitcher, className)} aria-label={`${label} - ${displayName}`} onClick={onClick}>
      {processedCountryCurrency} {`  |  `} {processedLang}
      <div
        className={sprinkles({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <Icon name={"Chevron"} direction="bottom" theme="black" width={4} />
      </div>
    </button>
  )
}

export { LangSwitcher, type LangSwitcherProps }
