"use client"

import type { ReactNode } from "react"

import type { PropsWithClassName } from "~/@types/generics"
import { AbstractDesktopTrigger } from "~/components/abstracts/Navigation/DesktopTrigger"
import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"
import { usePanelDisplayed } from "~/managers/NavigationManager"

type DesktopTriggerProps = PropsWithClassName<{
  index: number
  children: ReactNode | ((isActive: boolean) => ReactNode)
  links: NonNullable<TNavigation>["links"]
}>

export function DesktopTrigger({ index, children, className, links }: DesktopTriggerProps) {
  const panelDisplayed = usePanelDisplayed(links)

  return (
    <AbstractDesktopTrigger panelDisplayed={panelDisplayed} className={className} key={index} index={index}>
      {(isActive: boolean) => (typeof children === "function" ? children(isActive) : children)}
    </AbstractDesktopTrigger>
  )
}
