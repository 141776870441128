import { Accordion } from "@unlikelystudio/react-abstract-components"

import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"
import { ClientMobileLink } from "~/components/globals/Navigation/components/Mobile/ClientMobileLink"
import { MobilePanelFooter } from "~/components/globals/Navigation/components/Mobile/ClientMobilePanelFooter"
import { Panel } from "~/managers/PanelManager"

import * as css from "./styles.css"

type MobilePanelProps = {
  navigation: NonNullable<TNavigation>
}

export function MobilePanel({ navigation }: MobilePanelProps) {
  return (
    <Panel zIndex={40} scrollDisabled={true} bgColor="grey-alabaster" className={css.container}>
      <Accordion className={css.MobilePanel} autoHide={true}>
        {navigation.mobileLinks.map((item, index) => {
          return <ClientMobileLink index={index} item={item} key={`mobile_link_${index}`} />
        })}
      </Accordion>
      <MobilePanelFooter navigation={navigation} />
    </Panel>
  )
}
