"use client"

import { useRef } from "react"
import clsx from "clsx"

import { useIsHover } from "@unlikelystudio/react-hooks"
import Slider from "@unlikelystudio/react-slider"

import type { InferReturn, PropsWithClassName } from "~/@types/generics"
import type serializeFooterReassurance from "~/components/globals/Footer/components/FooterReassurance/_data/serializer"
import { FooterReassuranceItem } from "~/components/globals/Footer/components/FooterReassurance/components/FooterReassuranceItem"

import { hideFrom, showFrom } from "~/styles/utils/show-hide"

import * as css from "./styles.css"

type FooterReassuranceProps = PropsWithClassName<InferReturn<typeof serializeFooterReassurance>>

function FooterReassurance({ className, theme, items }: FooterReassuranceProps) {
  const customSliderRef = useRef<HTMLDivElement>(null)
  const [isHovered, callbacks] = useIsHover()

  return (
    <div
      className={clsx(css.FooterReassurance, css.reassuranceTheme({ backgroundColor: theme }), className)}
      {...callbacks}
    >
      <div className={clsx(css.footerContainer)}>
        <div className={css.footerWrapper} ref={customSliderRef}>
          <Slider
            className={clsx(css.slider, hideFrom("desktop", "block"))}
            infinite={true}
            autoIsSlider={false}
            customSliderRef={customSliderRef}
            autoPlay={{ speed: isHovered ? 0 : -0.4 }}
          >
            {items.map((item, index) => (
              <FooterReassuranceItem className={css.item} {...item} key={`reassurance_item_mobile_${index}`} />
            ))}
          </Slider>

          <div className={clsx(css.desktopWrapper, showFrom("desktop", "flex"))}>
            {items.map((item, index) => (
              <FooterReassuranceItem className={clsx(css.desktopItem)} {...item} key={`reassurance_item_${index}`} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export { FooterReassurance, type FooterReassuranceProps }
