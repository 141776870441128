import { env } from "~/env"

import { usePanelIndex } from "~/components/abstracts/Navigation/hooks"
import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"

// Replace : The second "null" by ex: : 0, : 1 to see the panels
export const debugModeIndex: number | null = env.NEXT_PUBLIC_NODE_ENV === "production" ? null : null

export function usePanelDisplayed(links: NonNullable<TNavigation>["links"]) {
  const [currentPanelIndex] = usePanelIndex()

  if (debugModeIndex !== null) {
    return (links?.[debugModeIndex]?.sublinks?.length ?? 0) > 0
  }

  if (currentPanelIndex === null) {
    return false
  }

  return (links?.[currentPanelIndex]?.sublinks?.length ?? 0) > 0
}

export function useCurrentPanel(links: NonNullable<TNavigation>["links"]) {
  const [currentPanelIndex] = usePanelIndex()
  const displayPanel = usePanelDisplayed(links)

  if (debugModeIndex !== null) {
    return displayPanel ? links?.[debugModeIndex] : null
  }

  if (currentPanelIndex === null) {
    return null
  }

  return displayPanel ? links?.[currentPanelIndex] : null
}
