export const PRODUCT_FRAGMENT = `
  name
  tags1
  productId
  url
  currencyCode
  imageUrl
  alternateImageUrls
  attributes {
    key
    value
  }
  skus {
    id
    name
    availability
    price
    listPrice
    inventoryLevel
    imageUrl
    attributes {
      key
      value
    }
  }
`
