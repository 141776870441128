import { atom } from "jotai"

import type { ExplicitAny } from "~/@types/generics"

export const cookiesConsentConfigAtom = atom<Record<string, ExplicitAny> | false>(false)

export const cookiesConsentDoneAtom = atom<boolean>((get) => {
  const config = get(cookiesConsentConfigAtom)
  return config && (Object.values(config)?.find((item: ExplicitAny) => item === true) ?? false)
})
