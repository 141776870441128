import { useEffect, useState } from "react"
import Cookies from "js-cookie"

import type { Nullish } from "~/@types/generics"
import { COOKIE_KEYS } from "~/lib/constants"

export function useSearchedTerms() {
  const [terms, setTerms] = useState<string[]>([])

  function getPreviousTerms() {
    const previousTerms = Cookies.get(COOKIE_KEYS.SEARCHED_TERMS)

    if (!previousTerms) return []

    try {
      return ((JSON.parse(previousTerms) as string[]) ?? []) as string[]
    } catch {
      return []
    }
  }

  function addTerm(term: Nullish<string>) {
    if (term) {
      const updatedValues = [term, ...getPreviousTerms()]?.filter(
        (term, index, array) => array?.indexOf(term) === index
      )

      setTerms(updatedValues)
      Cookies.set(COOKIE_KEYS.SEARCHED_TERMS, JSON.stringify(updatedValues), { expires: 7 })
    }
  }

  useEffect(() => {
    const previousTerms = getPreviousTerms()
    setTerms(previousTerms)
  }, [])

  return { terms: terms?.slice(0, 5), addTerm }
}
