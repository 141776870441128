import path from "path"
import type { ReadonlyURLSearchParams } from "next/navigation"
import Cookies from "js-cookie"

import i18nConfig from "~/config/i18n.json"
import { COOKIE_KEYS } from "~/lib/constants"
import { DEFAULT_LOCALE } from "~/lib/i18n/constants"
import { getMarketByCountry } from "~/lib/i18n/utils/get-i18n"
import { getPathnameWithoutLocale } from "~/lib/i18n/utils/get-pathname-without-locale"

export function getMarketRedirect({
  lang,
  country,
  locale,
  pathname,
  searchParams,
  shouldRedirectToHomePage,
}: {
  lang: string
  country: string
  locale: string
  pathname: string
  shouldRedirectToHomePage?: boolean
  searchParams: ReadonlyURLSearchParams
}) {
  const searchParamsWithPrefix = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : ""

  const newLocale =
    Object.entries(i18nConfig).find(([_, value]) => {
      return getMarketByCountry(country) === value.market && lang === value.lang
    })?.[0] ?? DEFAULT_LOCALE

  const pathnameWithoutLocale = getPathnameWithoutLocale(pathname)

  if (locale === newLocale) return null

  if (shouldRedirectToHomePage) {
    return path.join("/", newLocale)
  }

  // @COMMENT: UnComment if there are some pathsMapping
  // const splittedPathname = getSplittedPathname(pathname)
  // const currentEntity = getSplittedPathname(pathname)[1]
  // const route = Object.entries(pathsMapping).find(([_, value]) => {
  //   const allEntities = objectValues(value).flatMap((v) => [v.destination.split("/")[0], v.source.split("/")[0]])
  //   return currentEntity && allEntities.includes(currentEntity)
  // })?.[0]

  // if (route) {
  //   const currentSlug = splittedPathname[2]
  //   const url = getPath(newLocale, route, currentSlug ? { slug: currentSlug } : {})
  //   return getAlternateUrl(url + searchParamsWithPrefix)
  // }

  return path.join("/", newLocale, `${pathnameWithoutLocale}${searchParamsWithPrefix}`)

  // window.location.href = getAlternateUrl(
  //   path.join("/", newLocale, `${pathnameWithoutLocale}${searchParamsWithPrefix}`)
  // )
}

export const marketCookie = {
  country: {
    get: () => Cookies.get(COOKIE_KEYS.MARKET_COUNTRY_CODE),
    set: (country: string) => Cookies.set(COOKIE_KEYS.MARKET_COUNTRY_CODE, country, { expires: 365 }),
  },
  language: {
    get: () => Cookies.get(COOKIE_KEYS.MARKET_LANGUAGE_CODE),
    set: (language: string) => Cookies.set(COOKIE_KEYS.MARKET_LANGUAGE_CODE, language, { expires: 365 }),
  },
  hasInteracted: {
    get: () => JSON.parse(Cookies.get(COOKIE_KEYS.MARKET_HAS_INTERACTED) ?? "false") as boolean,
    set: (hasInteracted: boolean) =>
      Cookies.set(COOKIE_KEYS.MARKET_HAS_INTERACTED, JSON.stringify(hasInteracted), { expires: 365 }),
  },
}
