"use client"

import type { MouseEvent } from "react"
import { usePathname } from "next/navigation"
import clsx from "clsx"

import { AccordionPanel } from "@unlikelystudio/react-abstract-components"

import type { InferReturn, PropsWithClassName } from "~/@types/generics"
import InlineCta from "~/components/ui/InlineCta"
import Icon from "~/components/abstracts/Icon"
import { useNavigationMobileVisibility } from "~/components/abstracts/Navigation/hooks"
import { LinksListWithTitle } from "~/components/globals/Navigation/components/LinksListWithTitle"
import type { serializeMobileLink } from "~/components/globals/Navigation/components/Mobile/ClientMobileLink/_data/serializer"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePanel } from "~/managers/PanelManager"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

type ClientMobileLinkProps = PropsWithClassName<
  { item: InferReturn<typeof serializeMobileLink>[number] } & { index: number }
>

function ClientMobileLink({ className, item, index }: ClientMobileLinkProps) {
  const t = useTranslate()
  const pathName = usePathname()
  const { removeCurrent } = usePanel()
  const [, setIsBurgerMenuOpen] = useNavigationMobileVisibility()

  const activeLinkClick = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    e.preventDefault()
    removeCurrent()
    setIsBurgerMenuOpen(false)
  }

  const isActive = item.link?.href === pathName

  return item.linksLists && item.linksLists.length > 0 ? (
    <AccordionPanel
      key={`mobile_item_${index}`}
      ariaLabels={{ close: t("close"), open: t("open") }}
      className={clsx(className, css.AccordionItem)}
      headClassName={clsx(css.MobileLink, css.MobileLinkHead)}
      bodyClassName={css.MobileLinkBody}
      activeClassName={css.accordionIsActive}
      index={index}
      head={() => (
        <>
          <span className={sprinkles({ bgColor: item.isHighlighted ? "yellow-dolly" : "transparent" })}>
            {item.link?.children}
          </span>
          <Icon className={css.icon} name="Arrow" direction="right" width={12} />
        </>
      )}
      body={() => (
        <div className={css.bodyLists}>
          {item.linksLists.map((linksListWithTitle, subIndex) => (
            <LinksListWithTitle
              key={`link_list_${index}_${subIndex}`}
              activeLinkFn={activeLinkClick}
              {...linksListWithTitle}
            />
          ))}
        </div>
      )}
    />
  ) : (
    <div className={css.MobileLink} key={`mobile_item_${index}`}>
      <InlineCta
        className={sprinkles({ bgColor: item?.isHighlighted ? "yellow-dolly" : "transparent" })}
        size="medium"
        {...item.link}
        onClick={isActive ? (e) => activeLinkClick(e) : undefined}
        withLine={false}
      />
    </div>
  )
}

export { ClientMobileLink, type ClientMobileLinkProps }
