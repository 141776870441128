import type { ComponentProps } from "react"

import { usePanelIndex } from "~/components/abstracts/Navigation/hooks"

type AbstractDesktopPanelProps = ComponentProps<"div">

export function AbstractDesktopPanel({
  className,
  onMouseEnter,
  onMouseLeave,
  children,
  ...props
}: AbstractDesktopPanelProps) {
  const [currentPanelIndex, setPanelIndex] = usePanelIndex()

  return (
    <div
      data-comp={"Abstracts/Navigation/DesktopPanel"}
      className={className}
      {...props}
      onMouseEnter={(event) => {
        onMouseEnter?.(event)
        setPanelIndex(currentPanelIndex)
      }}
      onMouseLeave={(event) => {
        onMouseLeave?.(event)
        setPanelIndex(null)
      }}
    >
      {children}
    </div>
  )
}
