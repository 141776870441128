"use client"

import { useMarketPopinInit } from "~/components/ui/MarketPopin/hooks"

function MarketPopinInit() {
  useMarketPopinInit()
  return <></>
}

export default MarketPopinInit
