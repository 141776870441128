import { Suspense, type ComponentProps } from "react"

import Icon from "~/components/abstracts/Icon"
import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"
import { ClientBurgerCta } from "~/components/globals/Navigation/components/BurgerCta/index.client"

export type BurgerCtaProps = {
  navigation: NonNullable<TNavigation>
} & ComponentProps<"button">

export function BurgerCta(props: BurgerCtaProps) {
  return (
    <Suspense
      fallback={
        <button className={props.className}>
          <Icon height={12} name={"Menu"} />
        </button>
      }
    >
      <ClientBurgerCta {...props} />
    </Suspense>
  )
}
