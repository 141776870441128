"use client"

import { Suspense } from "react"

import { useIsSearchPanelOpen } from "~/components/ui/Panels/Search/hooks"
import Icon from "~/components/abstracts/Icon"
import { useNavigationEvents } from "~/components/abstracts/Navigation/hooks"
import { AbstractMobileToggleButton } from "~/components/abstracts/Navigation/MobileToggleButton"
import type { BurgerCtaProps } from "~/components/globals/Navigation/components/BurgerCta"
import { MobilePanel } from "~/components/globals/Navigation/components/Mobile/ClientMobilePanel"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePanel } from "~/managers/PanelManager"

export function ClientBurgerCta({ className, navigation, ...props }: BurgerCtaProps) {
  const { add, removeCurrent } = usePanel()
  const t = useTranslate()
  const [isSearchPanelOpen, setIsSearchPanelOpen] = useIsSearchPanelOpen()
  useNavigationEvents(() => removeCurrent())
  return (
    <Suspense>
      <AbstractMobileToggleButton
        onOpen={() => {
          setIsSearchPanelOpen(false)
          add(<MobilePanel navigation={navigation} />)
        }}
        onClose={() => {
          isSearchPanelOpen && setIsSearchPanelOpen(false)
          removeCurrent()
        }}
        className={className}
        openAriaLabel={t("cta_open_menu")}
        closeAriaLabel={t("cta_close_menu")}
        {...props}
      >
        {{
          open: <Icon height={14} width={24} name={"Close"} />,
          close: <Icon height={12} name={"Menu"} />,
        }}
      </AbstractMobileToggleButton>
    </Suspense>
  )
}
