import { useAtom, useAtomValue } from "jotai"

import { cookiesConsentConfigAtom, cookiesConsentDoneAtom } from "~/managers/CookiesConsentManager/store"

export function useCookiesConsentDone() {
  return useAtomValue(cookiesConsentDoneAtom)
}

export function useCookiesConsentConfig() {
  return useAtom(cookiesConsentConfigAtom)
}
