import { atom, useAtomValue, useSetAtom } from "jotai"

const userhasInteractedAtom = atom<boolean>(false)

export function useUserhasInteracted() {
  const userHasInteracted = useAtomValue(userhasInteractedAtom)
  const update = useSetAtom(userhasInteractedAtom)

  return { userHasInteracted, update }
}
