import { useQuery } from "@tanstack/react-query"

import type { Nullish } from "~/@types/generics"
import { getNostoRecommendationsProductCardSliders } from "~/lib/nosto/queries/apps-api/recommendations/get-pdp-recommendations"
import useLocale from "~/hooks/useLocale"

export type UseNostoRecommendationsProductCardSlidersParams = {
  type: "related" | "toplist" | "search"
  queryKey: Record<string, string | number>
  productIds?: Nullish<string>[]
  limit?: number
  enabled?: boolean
}

export const RECOS_LIMIT = 6

export function useNostoRecommendationsProductCardSliders({
  type,
  queryKey,
  productIds,
  limit = RECOS_LIMIT,
  enabled = true,
}: UseNostoRecommendationsProductCardSlidersParams) {
  const locale = useLocale()

  return useQuery({
    queryKey: ["nosto", type, queryKey, productIds],
    queryFn: async () => {
      return await getNostoRecommendationsProductCardSliders({ locale, type, productIds, limit })
    },
    enabled,
    keepPreviousData: true,
  })
}
