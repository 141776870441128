import type { GID, UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import { nostoAppsClient } from "~/lib/nosto/clients/private"
import {
  RELATED_RECOMMENDATIONS,
  SEARCH_RECOMMENDATIONS,
  TOPLIST_RECOMMENDATIONS,
} from "~/lib/nosto/queries/apps-api/recommendations/get"
import type {
  RecommendationsQueryResponse,
  RecommendationsQueryVariables,
} from "~/lib/nosto/queries/apps-api/recommendations/types"
import { getSfProductsByIds } from "~/lib/shopify/queries/get-sf-products-by-id"
import { getGIDFromReferenceId } from "~/lib/shopify/utils/id"
import { RECOS_LIMIT } from "~/hooks/nosto/useNostoRecommendationsProductCardSliders"
import type { UseNostoRecommendationsProductsParams } from "~/hooks/nosto/useNostoRecommendationsProducts"
import { serializeUnlikelyProductsToProductCardSliders } from "~/components/ui/ProductCardSlider/_data/serializer"

type GetNostoRecommendationsParams = Omit<UseNostoRecommendationsProductsParams, "queryKey" | "enabled"> & {
  locale: string
}

export async function getNostoRecommendationsProducts({
  locale,
  type,
  limit,
  productIds,
}: GetNostoRecommendationsParams) {
  const queryByType: Record<GetNostoRecommendationsParams["type"], { query: string; key: string }> = {
    search: {
      query: SEARCH_RECOMMENDATIONS,
      key: "search",
    },
    related: {
      query: RELATED_RECOMMENDATIONS,
      key: "related",
    },
    toplist: {
      query: TOPLIST_RECOMMENDATIONS,
      key: "toplist",
    },
  }

  const client = nostoAppsClient({ locale, tags: ["nosto", "recos"] })

  if (!client) return null

  const { query, key } = queryByType[type]

  const response = await client
    .request<RecommendationsQueryResponse, RecommendationsQueryVariables>(query, {
      limit: limit ?? RECOS_LIMIT,
      ...(productIds?.length && { productIds: productIds?.filter(Boolean) ?? [] }),
    })
    ?.catch(() => {
      return {
        recos: {
          [key]: { primary: [] },
        },
      }
    })

  const ids = (response?.recos?.[key]?.primary ?? [])
    ?.map((hit) => getGIDFromReferenceId(Number(hit?.productId), "Product"))
    .filter(Boolean)

  const { products } = await getSfProductsByIds({
    ids,
    locale,
  })

  return sortByGID(ids, products)
}

export async function getNostoRecommendationsProductCardSliders(props: GetNostoRecommendationsParams) {
  const unlikelyProducts = await getNostoRecommendationsProducts(props)

  const products = await serializeUnlikelyProductsToProductCardSliders(unlikelyProducts, props.locale)

  return { products }
}

function sortByGID(gids: GID[], products: UnlikelyProduct[]) {
  return gids.map((gid) => products.find((product) => product.id === gid)).filter(Boolean)
}
