import { useEffect, useState } from "react"

import { useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import { useGetCart } from "~/hooks/useGetCart"

export function useShouldPushEvents() {
  const { cartQueryIsComplete } = useGetCart()
  const { fetchStatus } = useGetCustomer()
  const customerQueryIsComplete = fetchStatus === "idle" || fetchStatus === "fetching"
  const [dataLayerAvailable, setDatalayerAvailable] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      if (window?.dataLayer) {
        setDatalayerAvailable(true)
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return cartQueryIsComplete && customerQueryIsComplete && dataLayerAvailable
}
