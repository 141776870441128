import { useEffect } from "react"
import { useDebounce } from "usehooks-ts"

import { lerp } from "@unlikelystudio/react-hooks"

import type { PropsWithClassName } from "~/@types/generics"
import useMousePosition from "~/hooks/useMousePosition"

export type SafeAreaProps = PropsWithClassName<{
  anchor: HTMLDivElement | null
  submenu: HTMLDivElement | null
  onMouseMove?(): void
}>

function SafeArea({ submenu, onMouseMove, className }: SafeAreaProps) {
  const mousePosition = useMousePosition()
  const debounceMousePosition = useDebounce(mousePosition, 50)
  const mouseX =
    mousePosition.x && debounceMousePosition.x ? lerp(mousePosition.x, debounceMousePosition.x, 0.05) : null
  const mouseY =
    mousePosition.y && debounceMousePosition.y ? lerp(mousePosition.y, debounceMousePosition.y, 0.05) : null

  useEffect(() => {
    onMouseMove?.()
  }, [mouseY, mouseX])

  if (!submenu) return null

  const { height: submenuHeight, x: submenuX, y: submenuY } = submenu.getBoundingClientRect() ?? {}

  if (mouseY === null || mouseX === null) return null
  const svgWidth = submenuX - mouseX + 4
  const svgHeight = submenuHeight

  return (
    <svg
      className={className}
      style={{
        position: "fixed",
        width: svgWidth,
        height: submenuHeight,
        pointerEvents: "none",
        zIndex: 2,
        top: submenuY,
        left: mouseX - 2,
      }}
    >
      <path
        pointerEvents="auto"
        fill="transparent"
        d={`M 0, ${mouseY - submenuY}
        L ${svgWidth},${svgHeight}
        L ${svgWidth},0
        z`}
      />
    </svg>
  )
}

export { SafeArea }
