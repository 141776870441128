import clsx from "clsx"

import type { InferReturn, PropsWithClassName } from "~/@types/generics"
import InlineCta from "~/components/ui/InlineCta"
import Icon from "~/components/abstracts/Icon"
import type serializeFooterSocials from "~/components/globals/Footer/components/FooterSocials/_data/serializer"

import * as css from "./styles.css"

type FooterSocialsProps = PropsWithClassName<InferReturn<typeof serializeFooterSocials>>

function FooterSocials({ className, items }: FooterSocialsProps) {
  return (
    <div className={clsx(css.FooterSocials, className)}>
      {items.map((item, index) => (
        <InlineCta
          key={`footer_social_item_${index}`}
          className={css.footerItem}
          {...item.link}
          withLine={false}
          aria-label={item.name}
        >
          <Icon direction="right" name={item.name} width={20} />
        </InlineCta>
      ))}
    </div>
  )
}
export { FooterSocials, type FooterSocialsProps }
