import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { Image } from "~/components/ui/Image"
import { Link, type LinkProps } from "~/components/ui/Link"
import type { FooterReassuranceProps } from "~/components/globals/Footer/components/FooterReassurance"

import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

const IMAGE_SIZES = [{ ratio: 24 / 375 }, { breakpoint: breakpoints.md, ratio: 24 / 1440 }]

type FooterReassuranceItemProps = PropsWithClassName<FooterReassuranceProps["items"][number]> & {
  link: LinkProps
}

function FooterReassuranceItem({ className, icon, title, subtitle, link }: FooterReassuranceItemProps) {
  return (
    <Link className={clsx(css.FooterReassuranceItem, className, css.itemTheme)} {...link}>
      {icon && icon.src && (
        <Image
          className={clsx(css.icon, css.item)}
          imageClassName={css.iconImage}
          sizesFromBreakpoints={IMAGE_SIZES}
          {...icon}
        />
      )}
      <p className={clsx(css.title, css.item)}>{title}</p>
      <p className={clsx(css.subtitle, css.item)}>{subtitle}</p>
    </Link>
  )
}

export { FooterReassuranceItem, type FooterReassuranceItemProps }
