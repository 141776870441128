import { gql } from "graphql-request"

import { PRODUCT_FRAGMENT } from "~/lib/nosto/queries/apps-api/fragments/product"

export const SEARCH_RECOMMENDATIONS = gql`
  query SearchRecommandations($term: String!, $limit: Int!) {
    recos(preview: false, image: VERSION_SOURCE) {
      search(term: $term, params: { minProducts: 1, maxProducts: $limit }) {
        primary {
          ${PRODUCT_FRAGMENT}
        }
      }
    }
  }
`

export const RELATED_RECOMMENDATIONS = gql`
  query RelatedRecommandations($limit: Int!, $productIds: [String!]!) {
    recos(preview: false, image: VERSION_SOURCE) {
      related(relationship: VIEWED_TOGETHER, productIds: $productIds, params: { minProducts: 1, maxProducts: $limit }) {
        primary {
          ${PRODUCT_FRAGMENT}
        }
      }
    }
  }
`

export const TOPLIST_RECOMMENDATIONS = gql`
  query RelatedRecommandations($limit: Int!) {
    recos(preview: false, image: VERSION_SOURCE) {
      toplist(hours: 168, sort: BUYS, params: { minProducts: 1, maxProducts: $limit }) {
        primary {
          ${PRODUCT_FRAGMENT}
        }
      }
    }
  }
`
