"use client"

import type { RecipeVariants } from "@vanilla-extract/recipes"

import type { PropsWithClassName } from "~/@types/generics"
import type { SbRichtext } from "~/lib/storyblok/schemas/default/richtext"
import type { LinkProps } from "~/components/ui/Link"
import ClientElement from "~/components/ui/UspBanner/Element/index.client"

import type * as css from "./styles.css"

export type TUspBanner = {
  richtexts: SbRichtext[]
  link?: LinkProps
} & RecipeVariants<typeof css.Element>
export type UspBannerProps = PropsWithClassName<TUspBanner>

function Element({ richtexts, ...props }: UspBannerProps) {
  return <ClientElement richtexts={richtexts} {...props} />
}

export default Element
