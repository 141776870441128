"use client"

import { useEffect, useState } from "react"
import Script from "next/script"
import { env } from "~/env"
import clsx from "clsx"
import { atom, useAtom } from "jotai"
import { useInterval } from "usehooks-ts"

import type { ExplicitAny } from "~/@types/generics"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

declare global {
  interface Window {
    zE: ExplicitAny
    zESettings: ExplicitAny
  }
}

function ZendeskScript() {
  return (
    <>
      <Script id="ze-snippet" src={`https://static.zdassets.com/ekr/snippet.js?key=${env.NEXT_PUBLIC_ZENDESK_KEY}`} />
    </>
  )
}

export function showZendeskWidget() {
  if (window?.zE) {
    window?.zE("webWidget", "show")
    window?.zE("webWidget", "open")
  }
}

export const isZendeskActiveAtom = atom(false)

export function useToggleActivateZendesk() {
  return useAtom(isZendeskActiveAtom)
}

export default function Zendesk({ enableButton = false }: { enableButton?: boolean }) {
  const [buttonVisibility, setButtonVisibility] = useState(true)
  const [loadZendesk, setLoadZendesk] = useState(false)
  const [isRunning, toggleIsRunning] = useState(true)
  const [isZendeskActive] = useToggleActivateZendesk()
  const [hasZendeskScriptLoaded, setHasZendeskScriptLoaded] = useState(false)

  useInterval(
    () => {
      if (window?.zE) {
        // Delete interval
        toggleIsRunning(false)
        window?.zE("webWidget:on", "close", () => window?.zE("webWidget", "hide"))
        window?.zE("webWidget", "show")
        window?.zE("webWidget", "open")
      }
    },
    isRunning ? 100 : null
  )

  useEffect(() => {
    if (isZendeskActive) onZendesk()
  }, [isZendeskActive])

  const onZendesk = () => {
    if (!hasZendeskScriptLoaded) {
      setLoadZendesk(true)
      setHasZendeskScriptLoaded(true)
    }

    setButtonVisibility(false)
    // In the case we changed the page, we're forcing the widget to show up
    showZendeskWidget()
  }

  const t = useTranslate()

  return (
    <div data-comp="Zendesk">
      {loadZendesk && <ZendeskScript />}
      {enableButton && (
        <button
          name="support_zendesk"
          aria-label={"Zendesk chat"}
          className={clsx(css.SupportZendesk, buttonVisibility ? css.isActive : null)}
          onClick={onZendesk}
        >
          <div className={css.SupportIcon}>
            <svg
              className={css.SupportIconSVG}
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 15 16"
              aria-hidden="true"
            >
              <path
                className={css.SupportIconSVG}
                d="M1.3,16c-0.7,0-1.1-0.3-1.2-0.8c-0.3-0.8,0.5-1.3,0.8-1.5c0.6-0.4,0.9-0.7,1-1c0-0.2-0.1-0.4-0.3-0.7c0,0,0-0.1-0.1-0.1 C0.5,10.6,0,9,0,7.4C0,3.3,3.4,0,7.5,0C11.6,0,15,3.3,15,7.4s-3.4,7.4-7.5,7.4c-0.5,0-1-0.1-1.5-0.2C3.4,15.9,1.5,16,1.5,16 C1.4,16,1.4,16,1.3,16z M3.3,10.9c0.5,0.7,0.7,1.5,0.6,2.2c0,0.1-0.1,0.3-0.1,0.4c0.5-0.2,1-0.4,1.6-0.7c0.2-0.1,0.4-0.2,0.6-0.1 c0,0,0.1,0,0.1,0c0.4,0.1,0.9,0.2,1.4,0.2c3,0,5.5-2.4,5.5-5.4S10.5,2,7.5,2C4.5,2,2,4.4,2,7.4c0,1.2,0.4,2.4,1.2,3.3 C3.2,10.8,3.3,10.8,3.3,10.9z"
              ></path>
            </svg>
          </div>

          <div className={css.SupportLabel}>{t("zendesk_chat")}</div>
        </button>
      )}
    </div>
  )
}
