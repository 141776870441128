"use client"

import { useState } from "react"
import clsx from "clsx"

import * as css from "./styles.css"

type ChildrenParameters = {
  onClose?: () => void
  onExit?: () => void
}

type AbstractStoryblokPreviewToasterProps = {
  className?: string
  title?: string
  titleClassName?: string
  buttonsWrapperClassName?: string
  children: (args: ChildrenParameters) => React.ReactNode
  enabled: boolean
}

function AbstractStoryblokPreviewToaster({
  className,
  title,
  titleClassName,
  buttonsWrapperClassName,
  enabled,
  children,
}: AbstractStoryblokPreviewToasterProps) {
  const [closed, setClosed] = useState(false)
  const disablePreview = () => {
    window.location.href = `/api/exit-preview?slug=${window.location.pathname}`
  }

  const onClose = () => {
    setClosed(true)
  }

  const onExit = () => {
    disablePreview()
  }

  return enabled ? (
    <div className={clsx(css.StoryblokPreviewToaster, className, { [css.closed]: closed })}>
      {title && <p className={titleClassName}>{title}</p>}
      {children && <div className={buttonsWrapperClassName}>{children({ onClose, onExit })}</div>}
    </div>
  ) : null
}

export { AbstractStoryblokPreviewToaster, type AbstractStoryblokPreviewToasterProps }
