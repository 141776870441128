"use client"

import { useEffect } from "react"
import { useUser } from "@auth0/nextjs-auth0/client"

import { useOnLoginSuccess } from "@unlikelystudio/react-ecommerce-hooks"

export default function Auth0ShopifyHandler() {
  const onLoginSuccess = useOnLoginSuccess()
  const { user } = useUser()

  useEffect(() => {
    const shopifyMultipassCustomerAccessToken = user?.shopifyMultipassCustomerAccessToken as string
    const shopifyMultipassCustomerAccessTokenExpiresAt = user?.shopifyMultipassCustomerAccessTokenExpiresAt as string

    if (!shopifyMultipassCustomerAccessToken) return
    onLoginSuccess({
      customerAccessToken: shopifyMultipassCustomerAccessToken,
      ...(shopifyMultipassCustomerAccessTokenExpiresAt ? { shopifyMultipassCustomerAccessTokenExpiresAt } : {}),
    })
  }, [user?.shopifyMultipassCustomerAccessToken])

  return null
}
