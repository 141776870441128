import { usePathname } from "next/navigation"

import { LOCALES } from "~/lib/i18n/constants"

function useIsHomepage() {
  const pathname = usePathname()
  const HOMEPAGE_PATHS = ["/", ...LOCALES.map((locale) => `/${locale}`)]
  return HOMEPAGE_PATHS.includes(pathname)
}

export default useIsHomepage
